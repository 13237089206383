import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import axiosInstance from '../../../api/axios'
import Swal from 'sweetalert2'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion';
import ReactTable from '../../../../_metronic/partials/widgets/React_Table/ReactTable'
import { FormControl, MenuItem, Select, Skeleton } from '@mui/material'
import { FaCopy, FaUserCircle } from "react-icons/fa";
import useAuthContext from '../../../Auth/AuthContext'
import { Images } from '../../../../_metronic/partials/widgets/React_Table/Images'

const UsersHome = () => {
    const intl = useIntl()
    const [loading, setLoading] = useState(false)
    const [Items, setItems] = useState(null)
    const [type, setType] = useState('user');
    const { check_role } = useAuthContext()
    const navigate = useNavigate();
    const [check, setCheck] = useState({
        'browse': false,
        'add': false,
        'show': false,
        'edit': false,
        'delete': false,
    })

    const CustomDiv = () => (
        <div className="row flex-end w-100">
            {check.add && (
                <div className="col-auto">
                    <Link to='/Users/Add-Users' type='button' className='btn btn-primary py-5' >
                        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                        {intl.formatMessage({ id: 'User.Create' })}
                    </Link>
                </div>
            )}
        </div>
    );

    const get_data = async () => {
        setLoading(true)
        const { data } = await axiosInstance.get('/admin-users', {
            params: { type: type },
        })
        console.log(data);
        setItems(data)
        setLoading(false)

    }

    const Delete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert ",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
                try {
                    const { data } = await axiosInstance.delete(`/admin-users/${id}`);
                    if (!data || data.length === 0) {
                        throw new Error(JSON.stringify(data));
                    }
                    get_data()
                } catch (error) {
                    Swal.showValidationMessage(`
                  Request failed: ${error}
              `);
                }
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Done!",
                    icon: "success"
                });
            }
        });

    }

    const columns = [
        {
            Header: intl.formatMessage({ id: 'Table.ID' }), accessor: 'id',
            Cell: row => (
                <div className=" w-100 flex-center">
                    #  {row.row.original.id}
                </div>
            )
        },


        {
            Header: intl.formatMessage({ id: 'Table.National_id' }), accessor: 'national_id',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.national_id ?? '---'}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.User' }), 
            accessor: (row) => `${row.name} ${row.email}`, // Custom accessor to combine name and email
            Cell: row => (
                <Link to={`/Users/Edit-Users/${row.row.original.id}`}>
                    <div className='d-flex align-items-center ps-3'>
                        <Images img={row.row.original.img} name={row.row.original.name} />
                        <div className='flex-grow-1'>
                            <div className='text-dark fw-bold text-hover-primary text-start fs-6'>
                                {row.row.original.name ? row.row.original.name : '---'}
                            </div>
                            <span className='text-muted d-block fw-semibold text-start'>
                                {row.row.original.email}
                            </span>
                        </div>
                    </div>
                </Link>
            )
        },
       
        {
            Header: intl.formatMessage({ id: 'Table.Phone' }), accessor: 'phone',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.phone}
                </div>
            )
        },
        {
            Header: intl.formatMessage({ id: 'Table.Type' }), accessor: 'type',
            Cell: row => (
                <div className=" w-100 flex-center">
                    {row.row.original.type}
                </div>
            )
        },
       
        {
            Header: intl.formatMessage({ id: 'Table.Action' }),
            Cell: row => (
                <div className="d-flex flex-center">
                    {check.edit && (
                        <Link to={`/Users/Edit-Users/${row.row.original.id}`} className='btn me-2  btn-light-primary'> {intl.formatMessage({ id: 'Table.Edit' })} </Link>
                    )}
                    {check.delete && (
                        <button onClick={(e) => Delete(row.row.original.id)} className='btn me-2  btn-light-danger'> {intl.formatMessage({ id: 'Table.Delete' })}  </button>
                    )}
                    {type === 'merchant' &&
                        <Link to={`/Users/Edit-User-Discounts/${row.row.original.id}`} className='btn me-2  btn-primary'> {intl.formatMessage({ id: 'Table.Discounts' })} </Link>
                    }
                </div>
            ),
        },
    ];

    useEffect(() => {
        setCheck({
            'browse': check_role('browse-users'),
            'add': check_role('add-users'),
            'show': check_role('show-users'),
            'edit': check_role('edit-users'),
            'delete': check_role('delete-users'),
        })

        if (!check_role('browse-users')) {
            navigate('/')
        }
        get_data()
    }, [type])
    return (
        <motion.nav
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, x: 100, transition: { duration: 0.2 } }} >
            <div className=" mb-5 mt-5 d-flex flex-stack">
                <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{intl.formatMessage({ id: 'Menu.Users' })}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                        <li className="breadcrumb-item text-muted">
                            <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Home' })}</li>
                        </li>
                        <li className="breadcrumb-item">
                            <span className="bullet bg-gray-400 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-muted">{intl.formatMessage({ id: 'Menu.Users' })}</li>
                    </ul>
                </div>
            </div>
            {Items !== null && !loading ?
                <ReactTable columns={columns} data={Items} customComponent={<CustomDiv />} />
                :
                <div className="row">
                    <div className="col-12">
                        <Skeleton variant="rounded" animation="wave" sx={{ width: '100%', height: '90vh', marginTop: '1vh' }} />
                    </div>
                </div>
            }

        </motion.nav>
    )
}
//get-banners
export default UsersHome