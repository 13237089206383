import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../../api/axios";
import { IoReloadOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import axios from "axios";

export default function EmailInput() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "Email is required";
      } else if (!/\S+@\S+\.\S+/.test(values.email)) {
        errors.email = "Invalid email address";
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const url = process.env.REACT_APP_AUTH_URL + "/send-email-otp"; 
      try {
        const response = await axios.post(url, {
          email: values.email,
        });

        if (response.status === 200) {
          // Swal.fire({
          //   icon: "success",
          //   title: "Success",
          //   text: "OTP sent successfully",
          // });
          navigate("/user-otp", {
            state: { type: "email", value: formik.values.email },
          });
        } else {
          // Swal.fire({
          //   icon: "error",
          //   title: "Error",
          //   text: "Failed to send OTP",
          // });
          setErrors({ email: "Failed to send OTP" });
        }
      } catch (error) {
        console.error("An error occurred:", error);
        Swal.fire({
          icon: "error",
          title: "Server Error",
          text: "Server error occurred",
        });
        setErrors({ email: "Server error occurred" });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div className="container mt-4" >
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            Email <span className="text-danger">*</span>
          </label>
          <input
          // style={{ width: '300px' }}
            className={`form-control py-4 ${
              formik.touched.email && formik.errors.email ? "is-invalid" : ""
            }`}
            id="email"
            name="email"
            type="email"
            placeholder="Enter your email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            required
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="invalid-feedback">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="row flex-end my-3">

          <button
            className="btn btn-primary w-50"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting && (
              <IoReloadOutline size={25} className="spinner-border" />
            )}
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
