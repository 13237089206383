import {createContext, useContext, useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import axios from '../api/axios'
import axiosInstance from '../api/axios'
import Pusher from 'pusher-js'
import sound from '../../_metronic/assets/Sounds/notification.mp3'
const AuthContext = createContext({})

export const AuthProvider = ({children}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const csrf = () => axios.get('/sanctum/csrf-cookie')

  const [user, setUser] = useState(null)
  const [roles, setRoles] = useState(null)

  const [check_login, seCheckLogin] = useState(1)
  const [ready, setReady] = useState(0)
  const [errors, setErrors] = useState([])
  const [is_user_signin, setIs_USer_signin] = useState(false)

  async function TokenSave(key, value) {
    console.log([key, value])
    localStorage.setItem(key, value)
    axios.defaults.headers.common['Authorization'] = `Bearer ${value}`
  }

  /*
        user = null ,
        user = {}
        user = 0
    */

  const getUser = async () => {
    try {
      const {data} = await axios.get('/user')
      setUser(data.user)
      setRoles(data.roles)
      if (!data.roles.includes('browse-dashboard') && !data.roles.includes('*')) {
        logout()
      }
    } catch (e) {
      setUser(0)
    }
    get_data_web()
  }

  const googlelogin = async ({...data}) => {
    setErrors([])
    try {
      const response = await axios.post('/Google-sign-in', data)
      TokenSave('access_token', response.data.token)
      getUser()
      navigate('/')
    } catch (e) {
      if (e.response.status === 422) {
        setErrors(e.response.data.errors)
      }
    }
  }

  const get_data_web = async () => {
    const {data} = await axiosInstance.get('/get-web-details', {
      params: {
        type: 'web',
      },
    })
    localStorage.setItem('web_name', data.name_en)
    localStorage.setItem('web_img', data.img)
  }

  const check_role = (role) => {
    if ((role && roles.includes(role)) || roles.includes('*')) {
      return true
    } else {
      return false
    }
  }
  const formatNumber = (number) => {
    if (number >= 1000000) {
      return (number / 1000000).toFixed(1) + 'm'; // Converts to millions (e.g. 10m)
    } else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'k'; // Converts to thousands (e.g. 10k)
    } else {
      return number.toString(); // Return the number itself if less than 1000
    }
  };
  //logout
  const logout = () => {
    axiosInstance.get(`/sign-out`).then((response) => {
      localStorage.removeItem('access_token')
      setUser(0)
      navigate('/login')
    })
  }

  const [pending_order, setPenidng_orders] = useState(0)

  const playSound = () => {
    new Audio(sound).play()
  }

  useEffect(() => {
    Pusher.logToConsole = false

    const pusher = new Pusher('c7ea9b9503139e809ab9', {
      cluster: 'mt1',
    })

    const channel = pusher.subscribe('new-order')
    channel.bind('new-order-event', function (data) {
      console.log(data)
      playSound()
      setPenidng_orders((prevCount) => prevCount + 1)
    })
  }, [])

  useEffect(() => {
    if (!user) {
      getUser()
    }
  }, [])

  useEffect(() => {
    setReady(0)
  }, [location])

  return (
    <AuthContext.Provider
      value={{
        user,
        getUser,
        logout,
        csrf,
        check_login,
        TokenSave,
        googlelogin,
        setReady,
        ready,
        get_data_web,
        playSound,
        pending_order,
        setPenidng_orders,
        check_role,
        formatNumber
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default function useAuthContext() {
  return useContext(AuthContext)
}
