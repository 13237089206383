import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Skeleton} from '@mui/material'
import moment from 'moment'
import Swal from 'sweetalert2'
import ReactTable from '../../../../../_metronic/partials/widgets/React_Table/ReactTable'
import axiosInstance from '../../../../api/axios'

const ArchiveUsers = () => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
 

  const get_data = async () => {
    setLoading(true)
    const {data} = await axiosInstance.get('/admin/users/archived')
    setData(data)
    setLoading(false)
  }


  const handleDelete = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const { data } = await axiosInstance.delete(`/admin/users/${id}/force-delete`);
          if (!data || data.length === 0) {
            throw new Error(JSON.stringify(data));
          }
          get_data()
        } catch (error) {
          Swal.showValidationMessage(`Request failed: ${error}`)
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Done!",
          icon: "success"
        });
      }
    });
  }

  const handleRestore = async (id) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          try {
            const { data } = await axiosInstance.post(`/admin/users/${id}/restore`);
            if (!data || data.length === 0) {
              throw new Error(JSON.stringify(data));
            }
            get_data()
          } catch (error) {
            Swal.showValidationMessage(`Request failed: ${error}`)
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Done!",
            icon: "success"
          });
        }
      });

  }

  const columns = [
    {
      Header: intl.formatMessage({id: 'Table.ID'}),
      accessor: 'id',
      Cell: ({row}) => <div className='w-100 flex-center'># {row.original.id}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Name'}),
      accessor: 'name',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.name}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Phone'}),
      accessor: 'phone',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.phone}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.Email'}),
      accessor: 'email',
      Cell: ({row}) => <div className='w-100 flex-center'>{row.original.email}</div>,
    },
    {
      Header: intl.formatMessage({id: 'Table.delete_at'}),
      accessor: 'created_at',
      Cell: ({row}) => (
        <div className='w-100 flex-center'>
          {moment(row.original.delete_at).format('YYYY-MM-DD hh:mm:ss A')}
        </div>
      ),
    },
    {
      Header: intl.formatMessage({id: 'Table.Action'}),
      Cell: ({row}) => (
        <div className='d-flex flex-center w-100'>
          <button
            className='btn me-2 btn-light-success'
            onClick={() => handleRestore(row.original.id)}
          >
            {intl.formatMessage({id: 'Table.Restore'})}
          </button>

          <button
          
            className='btn me-2 btn-light-danger'
            onClick={() => handleDelete(row.original.id)}
          >
            {intl.formatMessage({id: 'Table.Delete'})}
          </button>
        </div>
      ),
    },
  ]

  useEffect(() => {
        get_data()
  }, [])

  return (
    <div>
      {!loading ? (
        <ReactTable columns={columns} data={data} customComponent={<></>} />
      ) : (
        <div className='row'>
          <div className='col-12'>
            <Skeleton
              variant='rounded'
              animation='wave'
              sx={{width: '100%', height: '90vh', marginTop: '1vh'}}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ArchiveUsers
